import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import InfoBox from "../../components/InfoBox";
import QuoteBox from "../../components/QuoteBox";
import CtaPrimary from "../../components/CtaPrimary";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";


const breadCrumbLevels = {
  Home: "/",
  "Analytics Consulting": "/de/google-analytics-beratung",
  "GTM einrichten": "/de/google-tag-manager-einrichten"
};

// hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/google-tag-manager-setup"
);

// Schema
const FAQs = `
{
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Brauche ich Google Tag Manager?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Ja, weil Du höchstwahrscheinlich Google Analytics und andere Tags von Drittanbietern nutzen wollen wirst. Die Implementierung und Verwaltung von solchen Tags ist mit Google Tag Manager schneller und einfacher. Außerdem wird deine Ladegeschwindigkeit vergleichsweise besser sein."
        }
      },
      {
      "@type": "Question",
      "name": "Wie benutzt man Google Tag Manager mit Google Analytics?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "Log Dich mit deinem Google Konto bei Google Analytics ein, um deine Google Analytics Tracking ID samt Tracking Code zu erhalten. Installiere das Google Analytics Tag nicht direkt im Quelltext deiner Webseite. Besuch stattdessen tagmanager.google.com und hol dir einen Google Tag Manager Code, um Ihn auf allen Seiten deiner Website einzubinden. Log Dich bei GTM ein, implementiere den GA Code via Google Tag Manager und mach anschließend mit der Einrichtung von Google Analytics weiter. Alle möglichen Konfigurationen, wie z. B. das Tracken von Ereignissen oder das Hinzufügen anderer Marketing Tags, führst Du zukünftig via GTM durch."
        }
      },
      {
        "@type": "Question",
        "name": "Wann sollte ich Google Tag Manager benutzen?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Schon für die Einbindung von Google Analytics, solltest Du Google Tag Manager benutzen. Das Tracken von Ereignissen und die Konfiguration ist viel schneller und einfacher mit GTM. Außerdem gibt es viele Tutorials und Anleitungen online, die erklären, wie man z. B. Google Analytics einrichtet."
          }
        },
      {
        "@type": "Question",
        "name": "Was ist der Unterschied zwischen Google Analytics und Google Tag Manager?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Google Analytics ist die JavaScript Bibliothek, die die Daten der Webseitenbesuche sammelt. Google Tag Manager ist die JavaScript Bibliothek, die auf der Webseite läuft, um andere Bibliotheken oder Tools wie Google Analytics zu implementieren. Da andere Tools ihre eigenen JavaScript snippets voraussetzen, implementiert man sie alle durch GTM und kann dadurch Regeln und Einstellungen für alle Tags gleichzeitig benutzen."
        }
     },
     {
       "@type": "Question",
       "name": "Wo installiere ich den Google Tag Manager Code?",
       "acceptedAnswer": {
       "@type": "Answer",
       "text": "Der erste Teil des Tags sollte so hoch wie möglich im Head-Bereich eingebunden werden. Ich empfehle zwar das Tag im <head> zu installieren, jedoch nach <style> oder <script> Tags, die wichtig für den Seitenaufbau sind. Ihr Ladevorgang soll nicht durch's Tag verzögert werden. Der zweite Teil des Tags gewährleistet die fundamentale Funktionalität in Browsern mit deaktiviertem JavaScript. Setze das Tag so hoch wie möglich in das <body> Element. Die Logik hinter der hohen Positionierung dient dem schnellen Laden von GTM. Somit können selbst-definierte Regeln früher auf den Aufbauprozess der Seite einwirken."
       }
    },
    {
      "@type": "Question",
      "name": "Ist Google Analytics bei Google Tag Manager schon enthalten?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "Nein, aber Google Tag Manager ermöglicht es, Google Analytics in Sekunden zu installieren, mit nur ein paar Klicks. Du musst nur deine <a href='https://bluerivermountains.com/de/google-analytics-einrichten#google-analytics-tracking-code'>Google Analytics Tracking ID</a> kennen. Generell ist Google Tag Manager jedoch keine Voraussetzung für Google Analytics. Beide sind voneinander unabhängig."
      }
   },
   {
     "@type": "Question",
     "name": "Wo finde ich den Google Tag Manager?",
     "acceptedAnswer": {
     "@type": "Answer",
     "text": "Log dich mit deinem Google Konto bei tagmanager.google.com ein, um Dich in die Benutzeroberfläche von Google Tag Manager einzuloggen. Du erhältst den Google Tag Manager Code, wenn Du ein neues Konto erstellst und als Zielplatform Web wählst. Um Loslegen zu können, installiere den GTM Code-Schnipsel jetzt auf allen Seiten deiner Webseite."
     }
  }
  ]
}
`;

const GTMsetup = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Google Tag Manager einrichten (${currentYear} Tutorial)`}
        description="Lerne wie man Google Tag Manager richtig installiert und einrichtet. Wir nehmen Google Analyticcs, Tracking von Ereignissen, Remarketing einrichten und sogar den. sog. Data Layer durch."
        lang="de"
        canonical="/de/google-tag-manager-einrichten"
        image="google-tag-manager-tutorial-hero.png"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <ImgScreenshot
          src="gtm-setup/google-tag-manager-tutorial-hero.png"
          alt="user interface of Google Tag Manager"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />


        <H as="h1">Google Tag Manager einrichten</H>

        <p>Als <Link to="/de/google-tag-manager-consultant">Google Tag Manager Consultant</Link> habe ich GTM auf <b>100+ Kunden-Webseiten</b> eingebunden. In diesem Tutorial erkläre ich das Einrichten von Google Tag Manager Schritt für Schritt mit Beispielen und Videos zum Lernen.</p>
        <p>Weiter unten kannst Du das beschriebene <Link to="/de/google-tag-manager-einrichten#google-tag-manager-konfiguration-downloaden">GTM Setup herunterladen</Link> und in deinen Container importieren.</p>
        <p>Gehe direkt zur <Link to="/de/google-tag-manager-einrichten#google-tag-manager-installation">Installation</Link> oder in die <Link to="/de/google-tag-manager-einrichten#einrichtung-von-google-tag-manager">Google Tag Manager Einrichtung</Link>. Falls Du jedoch ein <b>Beginner</b> bist, schlage ich vor, dass Du erst liest, <em>wie</em> man ein <Link to="/de/tag-management">Tag Management System</Link> überhaupt zusammen mit anderen Services und Tools auf der
          Webseite benutzt.</p>
        <H as="h2">Wie benutzt man Google Tag Manager?</H>
        <p>Ich gehe davon aus, dass Du bereits weißt, <Link to="/de/was-ist-google-tag-manager">was Google Tag Manager ist</Link>. Lass uns also darüber sprechen, wie er funktioniert und wie man GTM benutzt.</p>
        <p>Idealerweise solltest Du nur <b>ein</b> Tag von einem Drittanbieter im Quelltext deiner Webseite oder Web-App haben.</p>
        <QuoteBox
          quote={`Das einzige Drittanbieter-Tag im Quelltext sollte von Google Tag Manager sein.`}
        />

        <p>Alle anderen Tags werden wiederum durch den Tag Manager implementiert. Andere Tags sind z. B. Google Analytics,
          Facebook, Twitter, Linkedin, Google Ads (früheres Adwords) und DoubleClick.</p>
        <p>Der Grund sind die <Link to="/de/google-tag-manager-vorteile">Vorteile von Google Tag Manager</Link>:</p>
        <ul>
          <li><b>schnelle und einfache</b> Einbindung von Marketing Tags</li>
          <li>über Unterseiten und mehrere Webseiten skalierbar</li>
          <li><b>eingebaute Trigger</b> für Form Tracking, Scroll Tracking und Video Tracking</li>
          <li>man kann Zugänge für mehrere GTM Konten verwalten</li>
          <li>ein bisschen <a rel="noopener" target="_blank" href="https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173">schnellere
            Ladezeit</a></li>
        </ul><br />
        <p>Wegen diesen Vorteilen <a rel="noopener" target="_blank" href="https://w3techs.com/technologies/overview/tag_manager">benutzen schon 30% aller Webseiten ein Tag
          Management System</a> und Google Tag Manager hat dabei einen Marktanteil von <a rel="noopener" target="_blank"
            href="https://trends.builtwith.com/analytics/tag-management/traffic/Entire-Internet">94%</a>.</p>
        <p>Das heißt, du solltest einen ziemlich guten Grund haben, wenn Du ein Tag nicht über GTM einbinden willst. Als
          Faustregel sollten <b>alle Tags durch den Google Tag Manager Container eingerichtet werden</b>.</p>
        <QuoteBox
          quote={`Benutz Google Tag Manager wie eine verbindende Schicht zwischen der Webseite und Tags von Drittanbietern.`}
        />
        <p>GTM ist quasi wie eine verbindende <b>Mittelschicht</b>. Ohne sie sind die Website und anderen Drittanbieter-Tags nicht miteinander verbunden. Tags von Drittanbietern sind meistens Dienste für Online Marketing oder Tracking Tools, die über JavaScript Bibliotheken eingebunden werden.</p>
        <p>Die einzige Ausnahme der Regel sind Split-Testing Tools.</p>
        <p>Der Grund ist, dass bei der Conversion-Optimierung verschiedenen Besuchergruppen unterschiedliche Varianten einer Seite gezeigt werden.<br />Während solchen A/B Tests wird beim Ladevorgang in Bruchteilen von Sekunden der Code der Seite geändert. So entsteht eine andere Test-Variante von der der Besucher nichts mitbekommen sollte. Daher zählt hier jede Millisekunde.</p>
        <p>Um also abrupte Designänderungen zu vermeiden, die für den Besucher sichtbar sind, sollte das Skript eines Split-Testing Tools weit oben und direkt im HTML-Code eingebunden werden.</p>
        <p>Puh. Jetzt, wo wir die Theorie abgearbeitet haben, lasst uns auf die Installation schauen.</p>
        <H as="h2">Google Tag Manager installieren</H>
        <p>Lasst uns das Google Tag Manager Tutorial starten, indem wir schauen, wo man den Google Tag Manager Code bekommt und wie man ihn installiert. Fürs Anmelden kannst Du dein gewöhnliches Google-Konto benutzen.</p>
        <ol>
          <li><H as="h3">Erstelle ein Google Tag Manager Konto</H>Um GTM zu installieren, musst du zuerst zur <a rel="noopener" target="_blank"
            href="https://tagmanager.google.com/">Google Tag Manager Webseite</a> gehen und ein neues Google Tag Manager Konto einrichten.<br />
            <ImgScreenshot
              src="google-analytics-einrichten/google-tag-manager-konto-erstellen.png"
              alt="Google Tag Manager Konto erstellen"
              className="article-img"
              caption="Klick auf Konto erstellen und gib den Kontonamen fürs GTM Konto an, z. B. der Name der Organisation, die die Webseite betreibt."

            />
          </li>
          <li><H as="h3">Erstelle einen Web-Container</H>Wähle <b>Web</b> als Zielplatform, um den Container Code für eine Website oder Web-App zu erhalten.<br />

            <ImgContainerFixed width="499px">
              <ImgScreenshot
                src="google-analytics-einrichten/google-tag-manager-container-einrichten.png"
                alt="Google Tag Manager web container erstellen"
                className="article-img"
                caption="Wähle einen Container-Namen (z. B. Name der Webseite) und erstelle einen GTM Container vom Typ Web, um ihn auf einer Website zu benutzen. Bemerke, dass es auch andere Containertypen für Mobile Apps und AMP-Seiten (Accelerated Mobile Pages) gibt."
              />
            </ImgContainerFixed>

          </li>
          <li><H as="h3">Implementiere den Google Tag Manager Code auf deiner Webseite</H>
            <ul>
              Danach wird Dir das Google Tag Manager <Link to="/de/wiki-analytics/container-tag-de">Container Tag</Link> gezeigt.<br />
              <ImgScreenshot
                src="google-analytics-einrichten/google-tag-manager-snippet.png"
                alt="Google Tag Manager code snippet"
                className="article-img"
                caption="Das ist das Google Tag Manager Code-Snippet. Es hat zwei Teile. Die Anleitung, wie das Skript einzubinden ist, steht über beiden Teilen."
              />
              <li>Der <b>erste Teil</b> des Codes wird so weit oben wie möglich im Head-Bereich auf allen Seiten platziert. Dadurch lädt das Tag Management System sehr früh, weshalb Regeln, die auf den Ladevorgang zugreifen, früher greifen, als wenn das Tag beispielsweise im Footer-Tag eingebunden wäre.
              </li>
              <li>Der <b>zweite Teil</b> vom Tag ist ein iFrame-Tag für Browser in denen JavaScript deaktiviert ist (daher das <code>&lt;noscript&gt;</code> Tag). Installiere den zweiten Teil so weit oben wie möglich im Body-Bereich auf allen Seiten deiner Webseite.<br />
                <ImgScreenshot
                  src="data-layer/positionierung-data-layer-gtm.png"
                  alt="Google Tag Manager Code Positionierung im Quelltext"
                  className="article-img"
                  caption="Der erste Pfeil zeigt auf den Data Layer. Keine Sorge, falls Du nicht weißt, was das ist. Danach kommt das GTM Tag (zweiter Pfeil). Der zweite Teil des Tags wird danach oben im Body-Bereich installiert (dritter Pfeil). Bemerke, dass andere Tags ruhig zwischendurch eingebunden sein können. Im Quellcode liegt der Data Layer jedoch immer VOR dem GTM Code und der zweite Teil des Tags kommt zum Schluss."
                />
              </li></ul></li>
        </ol>
        <p>So sieht die Vorgehensweise fürs Google Tag Manager Einbinden aus.</p>
        <p>Benutzt Du ein Content Management System? Falls ja, so hast du vielleicht Glück und dein CMS bietet ein <b>Plugin zur Installation von GTM</b> an.</p>
        <p>Trotzdem:</p>

        <InfoBox
          type="info"
          headline="Falls Dein CMS dir anbietet einige Tags durch andere Plugins zu intallieren"
          h="false"
          dots="true"
          tweetcopy="Falls Dein CMS dir anbietet einige Tags durch andere Plugins zu intallieren, vermeide es weitere Plugins oder Integrationen für Google Analytics, Facebook Pixel oder Google Ads zu installieren. Benutze stattedessen GTM."
        ><p>Vermeide es weitere Plugins oder Integrationen für Google Analytics, Facebook Pixel oder Google Ads zu installieren.</p>

          <p>Stattdessen, <b>benutze GTM für die Installation</b>.</p><br />
          <ol>
            <li>Du wirst dadurch schnellere Seitenladezeiten haben</li>
            <li>Du hast mehr Konfigurationsmöglichkeiten</li>
          </ol></InfoBox>
        <p>Die Benutzeroberfläche von Google Tag Manager erhält außerdem regelmäßig Updates mit neuen Funktionen, das heißt, man ist fast immer besser beraten die Einrichtung von Web Analytics und Marketing Tags mit GTM durchzuführen.</p><p>Außerdem sind die Gewinne im punkto Ladezeit gut für die <Link to="/de/wiki-analytics/bounce-rate-absprungrate">Bounce-Rate</Link> und die Suchemaschinenoptimierung.</p>
        <H as="h3">Google Tag Manager einbinden mit einem Plugin</H>
        <p>Folgend Anleitungen, um GTM mit einem Plugin in den meisten Content Management Systemen einzubinden.</p>

        <H as="h4">WordPress</H>
        <p>Für WordPress gibt es zwei gratis Plugins, die ich benutzen würde.</p> <p>Zuerst gibts den Klassiker <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/duracelltomi-google-tag-manager/">Google Tag Manager for Wordpress</a>.</p>
        <p>Außerdem gibt es auch <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/google-site-kit/">Site Kit by Google</a>, welches primär interessant ist, um ein Dashboard im Wordpress-Backend einzurichten. Es zeigt Google Analytics und Google Search Console Daten an. Das ist schon mal ziemlich cool, aber man kann darüber hinaus mit dem Plugin auch Google Tag Manager einbinden.</p>
        <H as="h4">Shopify</H>
        <p>Für Shopify gibt es ein gratis <b>Plugin zur GTM Installation</b>, welches kreativerweise <em><a rel="noopener"
          target="_blank"
          href="https://apps.shopify.com/trafficguard?surface_detail=google+tag+manager&amp;surface_inter_position=1&amp;surface_intra_position=6&amp;surface_type=search">Google
          Tag Manager Installer</a></em> heißt.</p>
        <H as="h4">Squarespace</H>
        <p>Für Squarepsace gibt es kein Plugin, aber man kann den Google Tag Manager installieren indem man wie folgt navigiert:<br />
          <b>Seitenmenü &gt; Settings &gt; Advanced &gt; Code Injection</b>.<br />
        </p> <ImgScreenshot
          src="gtm-setup/google-tag-manager-installation-in-squarespace.png"
          alt="Squarespace Einstellungen"
          caption={`In Squarespace kannst Du GTM installieren, indem du unter Settings > Advanced > Code Injection das Container-Snippet einfügst`}
          className="article-img"
        />
        <p>Füge das Tag so ein:<br />
        </p>
        <ImgScreenshot
          src="gtm-setup/gtm-code-injection-in-squarespace.png"
          alt="Füge das GTM Tag in Squarespace ein"
          caption={`Der erste Teil des GTM Tags kommt ins Header Feld und der zweite Teil ins Footer Feld.`}
          className="article-img"
        />
        <H as="h4">Wix</H>
        <p>Gehe zur Hauptnavigation der Wix Webseite auf der linken Seite. Danach klick auf <b>Marketing &amp; SEO</b> und danach auf <b>Marketing Integrationen</b>. Es werden dann mehrere Wix-Integrationen für z. B. Google Analytics oder Google Ads
          angezeigt, aber wir sind an dem Plugin für Google Tag Manager interessiert.<br />

        </p>

        <ImgScreenshot
          src="gtm-setup/google-tag-manager-einrichten-in-wix.png"
          alt="Wix Marketing-Integration für Google Tag Manager"
          caption={`In Wix gibt es eine Marketing-Integration für Google Tag Manager.`}
          className="article-img"
        />

        <p>Klick auf <em>verbinden</em>, um Google Tag Manager zu installieren.</p>



        <H as="h2">Wie kann man prüfen, ob GTM funktioniert?</H>
        <InfoBox
          type="info"
          headline="Wenn Du dich das erste Mal bei GTM einloggst"
          h="false"
          dots="true"
          tweetcopy="Wenn Du dich das erste Mal bei GTM einloggst...geh zum Senden-Button und publiziere einen leeren Container 😭. Ansonsten wird das GTM Skript keinen Container laden können und einen 400 Error anzeigen. 😉"
        >
          <p>Geh zum Senden-Button und publiziere einen <b>leeren Container</b>.</p>
          <p>Ansonsten wird das GTM-Skript nicht laden und einen <b>400 Error</b> anzeigen. Danach kannst Du dann stundenlang versuchen rauszubekommen warum. 😭<br /><br />Ist ein Klassiker 😉</p>


        </InfoBox>
        <p>Nachdem der GTM-Code eingebunden ist, kannst Du die unteren Möglichkeiten nutzen, um zu testen, ob der Google Tag Manager auch wirklich funktioniert.</p>
        <ol>
          <li><H as="h3">Container im Vorschau-Modus ansehen</H>Logge Dich ein und klick auf <b>in Vorschau ansehen</b>.<br />


            <ImgScreenshot
              src="event-tracking/google-tag-manager-vorschau-debugging-modus-aktivieren.png"
              alt="GTM Vorschau-Modus aktivieren"
              caption={`Aktiviere den Vorschau-Modus, um zu prüfen, ob Google Tag Manager funktioniert.`}
              className="article-img"
            />
            <br />
            Danach öffne einen neuen Tab im gleichen Browser und besuch deine Website. Wenn alles richtig funktioniert,
            sollte der GTM Debugger unten im Browser erscheinen.<br />
            Danach kannst Du den Vorschaumodus in GTM wieder beenden.</li>
          <li><H as="h3">Chrome Developers Tools</H><b>Öffne Chrome Developer Tools</b> mit einem rechts-Klick auf die Seite und danach auf <em>untersuchen</em>.
            (Alternativ F12 auf Windows und Shift + CTRL + J).<br />
            Geh danach zum <b>Netzwerktab</b> und <b>lade dann deine Webseite erneut</b> (F5 auf Windows oder CMD+Shift+R auf Mac). Der
            Netzwerktab wird dann alle Ressourcen für den Seitenaufbau auflisten.<br />
            Links oben gibt’s ein Feld zum Filtern. Gib <em>gtm.js</em> ein, um die Serverantwort für das Container-Snippet zu finden. Prüfe dann, ob der <b>Status Code 200</b> ist.<br /><br />
            Sieh hier wie es geht:<br /><br />
            <video
              loading="lazy"
              title={`Prüfe ob Google Tag Manager funktioniert`}
              autoplay
              loop
              controls
              css="max-width:100%;border:3px solid lightgrey;"
            >
              <source src="/video/check-if-gtm-is-working.mp4" type="video/mp4" />
            </video>
            <br />
            <b>Falls Du keinen 200 Status Code hast</b>: Hast Du vielleicht vergessen in GTM auf <b>senden</b> zu klicken
            und einen Container (ob leer oder nicht) zu veröffentlichen?</li>
          <li><H as="h3">Google Tag Assistent Browser Erweiterung</H>Installiere die Chrome-Erweiterung <a rel="noopener" target="_blank"
            href="https://chrome.google.com/webstore/detail/tag-assistant-by-google/kejbdjndbnbjgmefkgdddjlbokphdefk">Google Tag
            Assistant</a> und besuche deine Seite mit der Erweiterung aktiviert. Tag Assistent sollte dann
            eine GTM Container ID anzeigen:<br />


            <ImgContainerFixed width="452px">
              <ImgScreenshot
                src="gtm-setup/gtm-validation-with-tag-inspector.png"
                alt="Google Tag Inspector zeigt, ob GTM funktioneirt"
                caption={`Du kannst auch die Chrome-Erweiterung "Tag Assistent" benutzen, um zu prüfen ob GTM korrekt funktioniert.`}
                className="article-img"
              />
            </ImgContainerFixed>
          </li>
        </ol>
        <H as="h2">Einrichtung von Google Tag Manager</H>
        <p>Beim Einrichten von Google Tag Manager gibt es viele Möglichkeiten zur Konfiguration. D.h. <b><em>wie</em></b> GTM eingerichtet werden soll, hängt davon ab, mit welchen anderen Tools man plant in seinem Tag Management System zu arbeiten.</p>
        <p>Daher habe ich alle meine Google Tag Manager Tutorials zusammengestellt, um die gängigen Setups mit Beispielen und Videos zu erklären. Folge einfach den Anleitungen in diesem Google Tag Manager Guide und erstelle gleichzeitig eine solides Tag Management Setup auf deiner Website.</p>
        <p>Das Tutorial zur Einbindung eines Data Layers ist das einzige, das fundamentale Kenntnisse im Programmieren mit JavaScript voraussetzt.</p>
        <p><b>Hinweis</b>: Man kann grundsätzlich alle Einstellungen in GTM <b>manuell</b> durchführen, d.h. einzeln Event Tags und Trigger einstellen - und das ist das, was wir im Folgenden tun.<br />
          Dieser Vorgang ist einfacher, auf die meisten Setups übertragbar und befriedigt den Großteil der Bedürfnisse an ein
          Tracking Setup. Er ist jedoch nur begrenzt skalierbar.</p>
        <p>Große Webseiten wie Online-Shops benötigen eher <b>skalierbare Tag Management Lösungen</b>. Daher wird in solchen Fällen ein <Link to="/de/data-layer">Data Layer</Link> als zentrales Element für das Tracken von Ereignissen implementiert. Somit können Event-Handler zum Tracken von benutzerdefinierten Ereignissen genutzt werden. Einzelnes Aufsetzen von Tags, Triggern und Variablen ist somit nicht notwendig.</p>
        <ol>
          <li>
            <H as="h3">Google Analytics Tracking einrichten</H>
            <p>Dies ist wahrscheinlich der erste Schritt für alle. Lerne in diesem Tutorial die Einbindung von Google Analytics, komplett mit Zielvorhaben, Funnels
              und deinen eigenen Webseitenaufrufen ausgeschlossen. Plus weitere bewährte Einstellungen.</p>
            <Link to="/de/google-analytics-einrichten"><CtaPrimary color="purple" arrow="true">Google Analytics einrichten</CtaPrimary></Link>
          </li>
        </ol>

        <ol start="2">
          <li>
            <H as="h3">Event Tracking einrichten</H>
            <p>Sobald Google Analytics läuft und richtig eingestellt ist, ist der nächste Schritt das Engagement der
              Besucher zu tracken. Hier fragt man sich häufig: Wie oft wird auf einen bestimmten Knopf (oder ein anderes
              HTML Element) geklickt?<br />
              In meinem Tutorial zum Einrichten von <Link to="/de/event-tracking">Google Analytics Event Tracking</Link> erkläre ich am Beispiel eines <b>Button-Klicks</b>, wie man die Klicks trackt. Dieser Vorgang kann außerdem auch für alle anderen Arten von
              Klick-Tracking angewandt werden.</p>
            <Link to="/de/event-tracking"><CtaPrimary color="purple" arrow="true">Google Analytics Event Tracking</CtaPrimary></Link>
          </li>
          <li>
            <H as="h3">Remarketing einrichten</H>

            <p>Die üblichste Anwendung von GTM <em>nach</em> dem Installieren von Google Analytics, ist das Einrichten von
              Remarketing. Letztlich machen Tracking-Codes und Retargeting-Tags den Großteil von Drittanbieter-Tags aus, die üblicherweise
              den Quellcode verwirren.<br />
              Daher werden Code-Snippets für Retargeting durch ein Tag Management System eingebunden: Man genießt die <Link to="/de/google-tag-manager-vorteile">Vorteile von GTM</Link> und hält gleichzeitig den Quellcode sauber.</p>
            <p>Lass uns schauen, wie man die populärsten Retargeting-Dienste installiert, den <b>Facebook Pixel</b> und das <b>Google Ads Remarketing-Tag</b>.</p>
            <H as="h4">Facebook Pixel installieren</H>
            <ul>
              <li>
                Als erstes brauchst du eine <b>Facebook Pixel ID</b>. Besuche Facebook’s <a rel="noopener" target="_blank"
                  href="https://www.facebook.com/events_manager2/list/pixel/">Events Manager</a> und klick auf das <b>grüne Plus-Symbol</b>, um den Facebook Pixel zu erstellen. Danach wird die Pixel ID
                aufgelistet.<br />
                <ImgScreenshot
                  src="gtm-setup/get-fb-pixel.png"
                  alt="Event Manager in Facebook zeigt die Pixel ID an"
                  caption={`Finde deine Facebook Pixel ID im Event Manager.`}
                  className="article-img"
                />
              </li>
              <li>
                Dann in Google Tag Manager, erstelle ein <b>neues Tag</b> mit dem Namen <em>Facebook - Seitenaufruf</em> und besuche die <b>GTM Vorlagen</b>.
              </li>
              <li>
                Suche nach <em>Facebook</em> und klick auf den Facebook Pixel in den Suchergebnissen.<br />

                <ImgScreenshot
                  src="gtm-setup/fb-pixel-template-aus-der-gallerie.png"
                  alt="GTM's Vorlage für das Facebook Pixel Tag"
                  caption={`Der Facebook-Pixel wird durch eine GTM Vorlage eingebunden.`}
                  className="article-img"
                />
              </li>
              <li>
                Füge deine eben-erstelle <b>Facebook Pixel ID</b> ein und klick <b>speichern</b>.
              </li>
              <li>
                Stell den All-Pages-Trigger ein, sodass der Trigger auf <b>allen Seiten</b> reagiert.<br /><br />
                Hier alle Schritte im Video:

                <br /><br />
                <video
                  loading="lazy"
                  title={`Installiere den Facebook Pixel mit einer Vorlage in Google Tag Manager`}
                  autoplay
                  loop
                  controls
                  css="max-width:100%;border:3px solid lightgrey;"
                >
                  <source src="/video/facebook-pixel-in-gtm-hinzufügen.mp4" type="video/mp4" />
                </video>
              </li>
              <li>
                Klick danach <b>senden</b> oben rechts in der Ecke, um das Tag in die Live-Umgebung zu setzen.
              </li>
            </ul>
            <H as="h4">Einrichtung von Google Ads Retargeting</H>
            <ul>
              <li>
                Zuerst musst Du deine <b>Google Ads Conversion ID</b> für deine Google Ads Audience zur Verfügung
                haben. In Google Ads unter <b>Shared Library &gt; Audiences</b> findest Du die Informationen in den <b>Tag
                  Details</b> oder unter <b>Tag einstellen</b>.<br />
                <ImgContainerFixed width="432px">
                  <ImgScreenshot
                    src="gtm-setup/google-ads-conversion-id.png"
                    alt="Google Ads Conversion ID und Conversion Label"
                    caption={`Finde die Conversion ID und das Conversion Label in den Tag-Details der Google Ads Audience.`}
                    className="article-img"
                  />
                </ImgContainerFixed>
              </li>
              <li>
                In GTM erstellst Du dann ein neues Tag, z. B. mit dem Namen <em>Google Ads - Seitenaufruf</em>.
              </li>
              <li>
                Danach als Tag-Typ <b>Google Ads Remarketing</b> einstellen
              </li>
              <li>
                Füge dann die <b>Google Ads Conversion ID</b> und optional das <b>Conversion Label</b> in die
                entsprechenden Felder ein. Dann <b>Speichern</b>.
              </li>
              <li>
                Der Trigger sollte auf <b>allen Seiten</b> feuern.<br /><br />
                Hier dazu ein kurzes Video:<br />

                <video
                  loading="lazy"
                  title={`Install Google Ads page view with GTM`}
                  autoplay
                  loop
                  controls
                  css="max-width:100%;border:3px solid lightgrey;"
                >
                  <source src="/video/google-ads-retargeting-mit-gtm-installieren.mp4" type="video/mp4" />
                </video>

              </li>
              <li>
                Klick danach auf <b>speichern</b> und in der Übersicht <b>senden</b>, um die Einstellungen zu
                publizieren.
              </li>
            </ul>
          </li>
          <li>
            <H as="h3">Data Layer implementieren</H>
            <p>Wenn die oben genannten Anleitungen bei großen Webseiten zu arbeitsaufwendig werden, z. B. aufgrund von sehr vielen
              Ereignissen, dann empfiehlt es sich einen Data Layer zu implementieren.<br />
              Ein anderer Grund ist außerdem, dass Informationen von Datenbanken oder APIs mit in den Vorraussetzungen für Trigger, Tags und in den Ereignis-Daten genutzt werden können.<br />
              Durch einen Data Layer implementiert man Event-Tracking, dass skalierbar ist und speziell für E-Commerce und <b>große Webseiten</b> geeignet ist.</p>
            <Link to="/de/data-layer"><CtaPrimary color="purple" arrow="true">Data Layer</CtaPrimary></Link>
          </li>
        </ol>
        <H as="h2">Bewährte Konfigurationen für jedes GTM Setup</H>
        <p>Jedes mal, wenn ich Google Tag Manager einstelle, gibt es ein paar standard Konfigurationen, die ich jedes Mal aufsetzte. Diese Tag-Konfigurationen sind für fast alle Unternehmen relevant und hilfreich. Schau Dir die Liste
          der Konfigurationen an und implementiere, was dir hilft.</p>

        <p>Weiter unten kannst Du die folgenden <Link to="/de/google-tag-manager-einrichten#gtm-container-konfiguration-herunterladen">GTM Container-Konfigurationen herunterladen</Link>. Enthalten sind alle genannten Einstellungen.
        </p>
        <H as="h3">Ausgehende Links tracken</H>
        <p>Ausgehende Links zu tracken, bedeutet die <b>Klicks auf alle externen Links</b> mit Google Analytics zu tracken. Externe Links sind die, die von deiner Webseite zu einer anderen verweisen. Das Tracken von ausgehenden Links (sog. Outbound-Links) gibt Auskunft darüber, wie viel Traffic man zu anderen Websites sendet und für welche Themen sich die Webseitenbesucher interessieren.</p>
        <p>Um Klicks auf externe Links mit Google Analytics zu tracken, befolge diese <b>drei Schritte</b>:</p>
        <ol>
          <li>
            <H as="h4">Erstelle eine Variable für ein automatisches Ereignis</H>
            <ul>
              <li>Besuche die <b>Variablen</b> Übersicht und klick bei den benutzerdefinierten Variablen auf <b>Neu</b> &gt; <b>Variable konfigurieren</b> &gt; <b>Variable für automatisches Ereignis</b>.</li>
              <li>Die Variable soll die Adresse eines Links beim Klick untersuchen und entweder <code>true</code> ausgeben, wenn der Link zu einer anderen Website geht oder <code>false</code>, wenn es ein interner
                Link ist.</li>
              <li>Gib der Variable z. B. den Namen <em>Linkisoutbound</em> und wähle als <em>Variablentyp</em> <b>Element-URL</b> und für den <em>Komponententyp</em> <b>ist ausgehend</b>.<br />
                <ImgScreenshot
                  src="gtm-setup/variable-auto-ereignisse-externe-links.png"
                  alt="Konfiguration der Variable für automatische Ereignisse "

                  caption="Die benutzerdefinierte Variable für automatische Ereignisse hält den Wert TRUE, wenn die Website URL des geklickten Links extern ist, also zu einer anderen Webseite führt."

                  className="article-img"
                />


              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Erstelle einen neuen Trigger für ausgehende Links</H>
            <ul>
              <li>Gehe Links in die Navigation auf <em>Trigger</em> und klick <b>neu</b>, um einen neuen Trigger zu erstellen.</li>
              <li>Wähle als <em>Triggertyp</em> <b>nur Links</b> und nenne den Trigger <em>Event - Outbound Link</em>.
              </li>
              <li>Stell die Voraussetzungen für den Trigger so ein, dass er nur bei <b>einigen Klicks auf Links</b> reagiert, und zwar mit der Bedingung <b>Linkisoutbound</b> - <b>ist gleich</b> - <code>true</code>.<br />

                <ImgScreenshot
                  src="gtm-setup/outbound-links-trigger-einstellungen.png"
                  alt="Einstellungen für den Trigger von externen Links"

                  caption="Trigger-Einstellungen für Klicks auf ausgehende Links."


                  className="article-img"
                />

              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Google Analytics Ereignis für externe Links</H>
            <ul>
              <li>Gehe von der Übersicht zu Variablen und klicke bei den <em>integrierten Variablen</em> auf <b>konfigurieren</b>. Aktiviere danach die <b>Click-URL</b>. Danach geht’s wieder zurück zur Übersicht der Tags.</li>
              <li>Erstelle ein neues Tag und wähle in der Tag-Konfiguration den Tag-Typ <b>Google Analytics: Universal Analytics</b>.
                Benenne das Tag <em>Event - Outbound link</em> und setz den Tracking-Typ auf <b>Ereignis</b>.</li>
              <li>Die <b>Kategorie</b> erhält den String <code>Outbound link click</code>, <b>Aktion</b> ist <code>click</code> und <b>Label</b> soll auf die soeben aktivierte Variable verweisen. D.h. mit
                doppelten geschweiften Klammern <code>{`{{ Click URL }}`}</code>.</li>
              <li>Der Ereignis <b>Wert</b> kann auf <code>0</code> gesetzt werden, außer du kannst einen monetären Wert für
                den Klick auf deine externen Links errechnen (z. B. bei Affiliate-Links).</li>
              <li><b>Treffer ohne Interaktion</b> bleibt weiter eingestellt auf <em>falsch</em>, da Klicks auf ausgehende
                Links durchaus als User-Interaktion gelten.</li>
              <li>Die Variable für die <b>Google Analytics-Einstellungen</b> ist die selbe, wie für Seitenaufrufe.<br />


                <ImgScreenshot
                  src="gtm-setup/google-analytics-ereignis-tag-externe-links.png"
                  alt="Einstellungen des Google Analytics Ereignis Tag für ausgehende Links"

                  caption={`Tag für Google Analytics, um ausgehende Links als Ereignis zu tracken.`}
                  className="article-img"
                />

              </li>

              <li>Scrolle zum Schluss runter zum Trigger und wähle unseren neuen <em>Event - Outbound Link</em> Trigger.
                Danach <b>speichern</b> und <b>senden</b>, um den Container zu veröffentlichen.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Email Klicks tracken</H>
        <p>Das Tracken von Klicks auf Emails ist eine nützliche Methode, um den generellen Trend von Kontaktaufnahmen zum Webseitenbetreiber zu analysieren. Die Klicks auf E-Mail-Adressen pflegen mit Telefonanrufen und Besuchen im Ladengeschäft zu korrelieren.<br />
          Speziell für Geschäftsmodelle, die auf Lead-Generierung beruhen, ist diese Metrik daher ein wichtiger
          Leistungsindikator. Sie ermöglicht während der Analyse ansteigende oder fallende Trends zu erkennen.</p>
        <p>Um E-Mails in Google Analytics zu tracken, folge den nächsten Schritten:</p>
        <ol>
          <li>
            <H as="h4">Erstelle einen neuen Trigger für Emails</H>
            <ul>
              <li>Aktiviere als Erstes, bei den <em>integrierten Variablen</em> die <b>Click URL</b> Variable.</li>
              <li>Geh danach zu den <b>Triggern</b> und erstelle einen <b>neuen Trigger</b> mit dem Namen <em>Event -
                Mail click</em>. Den Triggertypen setzt du auf <b>nur Links</b> und setzt die Einstellung, sodass
                der Trigger nur bei <b>einigen Klicks auf Links</b> reagiert.</li>
              <li>Bei den Trigger-Bedingungen stellst Du ein: <b>Click URL</b> - <b>beginnt mit</b> und dann der String <code>mailto:</code>.<br />

                <ImgScreenshot
                  src="gtm-setup/email-klick-trigger-in-gtm.png"
                  alt="Trigger für Klicks auf Emailadressen"
                  caption={`Trigger fürs Tracken von Klicks auf Emailadressen.`}
                  className="article-img"
                />

              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Erstelle das Email-Tracking Ereignis für Google Analytics</H>
            <ul>
              <li>Unter Tags, erstelle jetzt ein neues Tag vom Typen <b>Google Analytics: Universal Analytics</b> und gib
                ihm z. B. den Namen <em>Event - Email link</em> und den <em>Tracking-Typ</em> <b>Ereignis</b>.</li>
              <li>Die <b>Kategorie</b> ist einfach der String <code>Email link click</code>, <b>Aktion</b> ist <code>click</code> und für das Label verweisen wir auf die Klick-URL Variable, die wir gerade aktiviert
                hatten, und zwar so: <code>{`{{ Click URL }}`}</code>.</li>
              <li>Der Ereignis-Wert kann auf <code>0</code> gesetzt werden, außer du kannst einen sinnvollen Wert für den
                Klick auf die Emailadresse definieren. Wenn z. B. jeder zehnte Emailkontakt zu einem 100€ Verkauf führt,
                dann ist der durchschnittliche Wert 10€ per Klick.</li>
              <li><b>Treffer ohne Interaktion</b> bleibt auf <em>falsch</em> eingestellt, da solch ein Klick willentlich
                geschieht und daher eine aktive Interaktion darstellt.</li>
              <li>Die Variable für <b>Google Analytics-Einstellungen</b> ist dieselbe wie in Deinem Google Analytics Tag
                für Seitenaufrufe.<br />


                <ImgScreenshot
                  src="gtm-setup/google-analytics-ereignis-tag-emails.png"
                  alt="GA Ereignis Tag Einstellungen"
                  caption={`Einstellungen für das GA Email Klick Ereignis.`}
                  className="article-img"
                />


              </li>
              <li>Zum Schluss, scroll runter zum Trigger und wähle den Trigger <em>Event - Mail click</em>, den wir vorher
                erstellt haben. Danach sind wir fertig und Du kannst in der Übersicht auf senden klicken, um die
                Änderungen live zu setzen.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Klicks auf Telefonnummern tracken</H>
        <p>Tracking von Klicks auf Telefonnummern ist primär sinnvoll für mobile Endgeräte, da ein Tap auf eine Nummer einen
          Telefonanruf auslöst.<br />Auf Desktop Computern geschieht bei solch einem Klick meistens nichts.</p>
        <p>Genau wie mit dem Tracken von Klicks auf Emailadressen, sind Tabs auf Telefonnummern ein Leistungsindikator der mit anderen Arten der
          Kontaktaufnahme korreliert. D.h. der Wert liegt darin, bei der Webanalyse eine weitere Metrik zu haben, die andere Annahmen bestätigen oder verwerfen kann.</p>
        <p>Um GTM für das Tracken von Anrufen einzurichten, folge diesen Schritten:</p>
        <ol>
          <li>
            <H as="h4">Erstelle einen Trigger für Klicks auf Telefonnummern</H>
            <ul>
              <li>Wie bei den anderen Beispielen müssen wir erst sicherstellen, dass die <b>Click URL</b> Variable bei
                den <em>integrierten Variablen</em> aktiviert ist.</li>
              <li>Erstelle danach einen <b>neuen Trigger</b> mit dem Namen <em>Event - Phone click</em> und dem Triggertyp <b>nur Links</b>, der nur bei <b>einigen Klicks auf Links</b> reagiert.</li>
              <li>Die Voraussetzungen für den Trigger danach wie folgt ausfüllen: <b>Click URL</b> - <b>beginnt mit</b> und
                dann der String <code>tel:</code>. Danach <b>speichern</b>.<br />


                <ImgScreenshot
                  src="gtm-setup/telefon-trigger-in-gtm.png"
                  alt="Telefonnummer Klick Trigger"
                  caption={`Trigger für Klicks auf Telefonnummern.`}
                  className="article-img"
                />

              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Erstelle ein Google Analytics Tag für das Telefon-Klick Ereignis</H>
            <ul>
              <li>Füge wieder ein <b>Universal Analytics Tag</b> mit dem Tracking-Typ <b>Ereignis</b> und mit dem Namen <em>Event - Phone clicks</em> zum Container hinzu.</li>
              <li>Die <b>Kategorie</b> ist ein statischer String <code>Phone number click</code>, die <b>Aktion</b> nennen
                wir <code>click</code> und das Label soll den geklickten Link enthalten, also die <code>{`{{ Click URL }}`}</code>.</li>
              <li>Als <b>Wert</b> benutze den durchschnittlichen nominalen Wert, den ein Anruf deinem Unternehmen bringt. Falls das schwierig auszurechnen ist, belass den Wert auf <code>0</code>.</li>
              <li><b>Treffer ohne Interaktion</b> bleibt auf <code>falsch</code> eingestellt, da ein solcher Klick
                durchaus als interaktion gewertet werden sollte.</li>
              <li>Die Variable für <b>Google Analytics-Einstellungen</b> sollte dieselbe sein, wie für die Seitenaufrufe.



                <ImgScreenshot
                  src="gtm-setup/ga-ereignis-telefonnummer.png"
                  alt="GA Ereignis Telefonnummern"
                  caption={`GA Ereignis für Telefonnummer-Klicks.`}
                  className="article-img"
                />


              </li>
              <li>Zum Schluss musst Du noch runterscrollen und den Trigger <em>Event - Phone click</em>, den wir gerade
                vorher erstellt haben, zu diesem Tag hinzufügen. Danach <b>speichern</b> und den Container zur
                Veröffentlichung <b>senden</b>.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Track Downloads</H>
        <p>Download-Tracking in Google Analytics ist ein guter Indikator für Engagement. Downloads können z. B. für eBooks,
          Excel-Sheets, Bilder, Videos oder Musik getrackt werden.</p>
        <p>Bei der Analyse von Besuchergruppen ist Download-Tracking ist ein super Indikator zur Kategorisierung von Zielgruppen, da der Download ein klares Interesse in den Inhalt signalisiert.</p>
        <p>Um Tracking von Downloads einzustellen, folge diesen Schritten:</p>
        <ol>
          <li>
            <H as="h4">Download-Trigger einrichten</H>
            <ul>
              <li>Füge einen neuen Trigger hinzu mit dem Namen <em>Event - Downloads</em>. Der Triggertyp ist wieder <b>nur Links</b> und es sollte nur bei <b>einigen Klicks auf Links</b> reagiert werden.</li>
              <li>Die Bedingungen für den Trigger sind <b>Click URL</b> - <b>endet auf</b> und dann z. B. der String <code>.pdf</code>, um PDF-Downloads zu tracken. Ändere die Dateiendung je nach Bedarf für den
                Dateitypen, den Du zum Download anbietest.</li>
              <li>Falls Du <b>mehrere Dateitypen</b> zum Download anbietest, stell die Bedingung wie folgt ein: <b>Click
                URL</b> - <b>stimmt überein mit regulärem Ausdruck (Groß-/Kleinschreibung ignorieren)</b> und dann
                dieser String: <code>.pdf|.mp4|.mp3|.xlsx|.xls|.epub|.jpeg$</code>. Füge andere Dateiendungen hinzu bei
                Bedarf. Danach <b>speichern</b>.<br />



                <ImgScreenshot
                  src="gtm-setup/trigger-downloads.png"
                  alt="Trigger für Download-Tracking"
                  caption={`Trigger Einstellungen zum Tracken von Downloads.`}
                  className="article-img"
                />


              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Google Analytics Ereignis Tag für Download-Tracking</H>
            <ul>
              <li>Füge ein <b>Google Analytics: Universal Analytics</b> Tag hinzu und stelle den Tracking-Typ auf <b>Ereignis</b> ein.</li>
              <li>Die Ereignis-Kategorie ist <code>Download</code>, die <b>Aktion</b> <code>click</code> und als Label
                stellen wir wieder <code>{`{{ Click URL }}`}</code> ein.</li>
              <li><b>Treffer ohne Interaktion</b> bleibt weiterhin auf <code>falsch</code> eingestellt. Solch ein Klick
                ist willentlich und aktiv.</li>
              <li>Die Variable für die Google Analytics-Einstellungen bleibt die selbe wie in deinen anderen GA
                Tags.<br />


                <ImgScreenshot
                  src="gtm-setup/ga-ereignis-tag-downloads.png"
                  alt="Ereignis-Tag für Download Tracking"
                  caption={`Google Analytics Ereignis-Tag für Download Tracking.`}
                  className="article-img"
                />

              </li>
              <li>Weiter unten jetzt nur noch den soeben erstellten Trigger <em>Event - Downloads</em> einstellen.</li>
              <li>Fast fertig. Klick <b>speichern</b> und danach auf <b>senden</b> in der Übersicht.</li>
            </ul>
          </li>
        </ol>
        <H as="h2">Anleitung zum Testen von GTM Tag und Trigger</H>
        <p>Um die vorhergehenden Konfigurationen zu testen, hast Du mehrere Möglichkeiten:</p>
        <ol>
          <li><H as="h3">GTM Debugger</H>
            <p>Klicke in der Übersicht auf <b>in Vorschau ansehen</b> oben rechts neben dem Senden-Button. Öffne danach einen neuen Tab im <b>gleichen Browser</b> und schon solltest du ein kleines Fenster, den <b>Debugger</b>, am unteren Bildrand aufspringen sehen.</p>
            <p>Führe eines der Scenarios, für das Du gerade Ereignis-Tracking aufgesetzt hast, selber aus und schaue ob der
              GTM Debugger Dir die richtigen Ereignis-Daten anzeigt. Klick z. B. auf einen Download Link, um das Ereignis
              zum Download-Tracken auszulösen.</p>

            <ImgScreenshot
              src="event-tracking/google-tag-manager-debugging-mode.png"
              alt="Google Tag Manager Debugging-Modus"
              className="article-img"
              caption={`Der Google Tag Manager Debugger öffnet sich automatisch, nachdem der Vorschau-Modus aktiviert wurde.`}
            />
          </li>
          <li><H as="h3">Google Analytics Echtzeit-Bericht für Ereignisse</H>
            <p>Eine andere Methode, ist sich in Google Analytics einzuloggen und den <b>Echtzeit-Bericht für Ereignisse</b> zu überprüfen. Die benutzerdefinierten Ereignisse sollten dort auftauchen, kurz nach dem man ein Ereignis getriggert hat.</p>
            <ImgScreenshot
              src="event-tracking/google-analytics-event-tracking-echtzeit-report.png"
              alt="Google Analytics Echtzeit-Bericht"
              className="article-img"
              caption={`Im Google Analytics Echtzeit Report kannst du überprüfen, ob alle Ereignisse wie erwartet eintreffen.`}
            />
          </li>
          <li><H as="h3">Chrome-Erweiterung für Debugging</H>
            <p>Installiere eine Chrome-Erweiterung zum Debuggen der Tags und Trigger in GTM. Ich empfehle <a rel="noopener" target="_blank" href="https://chrome.google.com/webstore/detail/trackie/iphjjodolgbelaogcefgpegebgecopeh">Trackie</a> oder <a rel="noopener" target="_blank" href="https://chrome.google.com/webstore/detail/omnibug/bknpehncffejahipecakbfkomebjmokl">Omnibug</a>,
              aber es gibt auch noch viele andere <Link to="/de/data-layer#extensions-f">Erweiterungen zum Debuggen</Link>.<br />
              Nachdem Du die Chrome-Erweiterung installiert hast, kannst Du in <b>Chrome Developer Tools</b> einen neuen Tab für die jeweilige Extension nutzen.<br />
              Während Du dann auf der Seite surfst und Ereignisse auslöst, zeigt der neue Tab alle Informationen die mit GTM und Google Analytics zusammenhängen.</p>

            <ImgScreenshot
              src="gtm-setup/test-gtm-trigger-and-tag-with-trackie.png"
              alt="Trackie Chrome-Erweiterung zeigt Ereignis Daten."
              className="article-img"
              caption={`Trackie Chrome-Erweiterung zeigt Ereignis-Daten in Chrome Developer Tools.`}
            />

            <ImgScreenshot
              src="gtm-setup/test-gtm-trigger-and-tag-with-omnibug.png"
              alt="Omnibug Chrome-Erweiterung zeigt Daten der Ereignisse zum Debuggen."
              className="article-img"
              caption={`Omnibug Chrome-Erweiterung zeigt Daten der Ereignisse zum Debuggen in Chrome Dev Tools.`}
            />
          </li>
        </ol>
        <H as="h2">GTM Container Konfiguration herunterladen</H>
        <p>Da die oben genannten Konfigurationen für fast alle Webseiten hilfreich sind, habe ich den Google Tag Manager Container zum Download bereitgestellt, sodass Du die Einstellungen in deinen Container importieren kannst.<br />
          Es ist eine <code>.json</code> Datei, die die einzelnen beschriebenen Schritte schon vorkonfiguriert enthält. Du kannst die Datei einfach importieren, ohne die Tags, Trigger und Variablen selber einstellen zu müssen.</p>
        <p>Entweder benutzt Du die Konfigurationsdatei in einem <b>neuen Container</b>, um Zeit beim Einbinden der anderen Tags zu sparen, oder Du importierst den Container in ein schon <b>existierendes Setup</b>. Du musst dann nur die
          Variable für die Google Analytics-Einstellungen zu deiner eigenen ändern.</p>
        <p>Die folgenden Tag-Konfigurationen sind im Container schon aufgesetzt und können heruntergeladen und dann importiert
          werden:</p>
        <ul>
          <li>Tracking für externe Links</li>
          <li>Email Tracking</li>
          <li>Telefonnummer Tracking</li>
          <li>Download Tracking</li>
        </ul>
        <p className="baseline">Importiere die Container Einstellungen und sende sie live. Zu Demonstrationszwecken habe ich eine Google Analytics-Einstellungsvariable mit der Google Analytics Tracking ID <em>UA-12345678-9</em> aufgesetzt.</p>
        <p><b>Ersetzte den Google Analytics Tracking Code mit deinem eigenen</b> oder <b>stelle deine eigene GA-Einstellungsvariable in den Ereignis-Tags ein</b>.</p>
        <p>Lade die GTM Konfiguration herunter, um Sie in deinen Container zu importieren.</p>
        <a href="/downloads/best-practice-gtm-configs.json" download="best-practice-gtm-configs.json" ><CtaPrimary color="purple">GTM Einstellungen herunterladen</CtaPrimary></a>
        <H as="h3">Importiere Container-Einstellungen in deinen GTM Container</H>
        <p>Folge den folgenden Schritten, um die GTM Konfiguration zu importieren:</p>
        <ul>
          <li>
            <p>in GTM, gehe zu <b>Verwaltung</b> &gt; <b>Container importieren</b>.</p>
          </li>
          <li>
            <p>Wähle die Container Datei, die Du gerade heruntergeladen hast.</p>
          </li>
          <li>
            <p>wähle einen <b>neuen Arbeitsbereich</b> mit dem Namen <em>Import Bluerivermountains</em>.</p>
          </li>

          <li>
            <p>Wähle <b>zusammenführen</b> als Importoption und dann <b>In Konflikt stehende Tags, Trigger und Variablen
              umbenennen</b>.</p>
          </li>
          <li>
            <p>Klick <b>bestätigen</b>, dann <b>senden</b> und <b>veröffentlichen</b>, um die Änderungen anzuwenden.</p><br /><br />Hier ein Video zum Vorgang:

            <video
              loading="lazy"
              title={`Tutorial um Google Tag Manger Container zu importieren`}
              autoplay
              loop
              controls
              css="max-width:100%;border:3px solid lightgrey;"
            >
              <source src="/video/gtm-container-importieren.mp4" type="video/mp4" />
            </video>
          </li>
          <li>
            <p>Zu guter Letzt muss jetzt noch die Tracking ID in der Variable für die Google Analytics-Einstellungen auf deine eigene GA Tracking ID abgeändert werden.</p>
          </li>
        </ul>
        <H as="h2">Anmerkung zu persönlichen Daten</H>
        <p>Wir müssen uns bewusst sein, was für Informationen wir tracken. Daten sind nicht gleich Daten, da viele Länder unterschiedliche Regelungen zum Umgang mit persönlichen Daten haben und somit beeinflussen, welche Informationen wir einsammeln dürfen.</p>
        <p>Gleichzeitig gibt es weitere Nutzungsrichtlinien auf Google's Seite, die es verbieten persönliche Daten zu Google's Server zu senden.</p>
        <p>Daher:</p>
        <p>Grundsätzlich sind <b>Emails und Telefonnummern persönlich identifizierbare Informationen (PII)</b> und wir dürfen
          solche Daten nicht in ein Google Analytics Konto senden, da es gegen die <a rel="noopener" target="_blank"
            href="https://support.google.com/analytics/answer/2795983">Nutzungsbedingungen</a> verstößt. Unsere eigene
          Telefonnummer oder Emailadresse, die wir öffentlich auf der Webseite abbilden, sind jedoch keine persönlichen Daten des Besuchers.</p>
        <p>Wenn Google Analytics jedoch die Datenbank nach solchen Daten durchleuchten würde, wäre die Legitimität nicht sofort
          zu erkennen. Die Daten sehen potenziell wie PII Daten aus.<br />
          Daher empfehle ich, kein Risiko einzugehen und <b>Emailadressen oder Telefonnummern, bevor sie zum Google Analytics Konto gesendet werden, zu verschleiern</b>.<br />
          Simo Ahava hat dazu ein super Custom Task Script geschrieben, welches <a rel="noopener" target="_blank"
            href="https://www.simoahava.com/gtm-tips/remove-pii-google-analytics-hits/">PII Daten in Google Analytics Hits
            verschleiert</a>.</p>
        <p>Es ist kein Muss, aber die Implementierung vermeidet potenzielle Verwirrung auf Google’s Seite, ob Daten als
          Nutzerdaten anzusehen sind oder nicht.</p>
        <H as="h2">FAQ</H>
        <H as="h3">Brauche ich Google Tag Manager?</H>
        <p>Ja, weil Du höchstwahrscheinlich Google Analytics und andere Tags von Drittanbietern nutzen wollen wirst. Die
          Implementierung und Verwaltung von solchen Tags ist mit Google Tag Manager schneller und einfacher. Außerdem wird
          deine <a rel="noopener" target="_blank"
            href="https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173">Ladegeschwindigkeit
            vergleichsweise besser sein</a>.</p>
        <H as="h3">Wann sollte ich Google Tag Manager benutzen?</H>
        <p>Schon für die Einbindung von Google Analytics, solltest Du Google Tag Manager benutzen. Das <Link to="/de/event-tracking">Tracken von Ereignissen</Link> und die Konfiguration ist viel <b>schneller</b> und <b>einfacher</b> mit GTM. Außerdem gibt es viele Tutorials und Anleitungen online, die erklären, wie man z. B. <Link to="/de/google-analytics-einrichten">Google Analytics einrichtet</Link>.</p>
        <H as="h3">Wie benutzt man Google Tag Manager mit Google Analytics?</H>
        <p>Log Dich mit deinem Google Konto bei Google Analytics ein, um deine Google Analytics Tracking ID samt Tracking Code zu erhalten. Installiere das Google Analytics Tag nicht direkt im Quelltext deiner Webseite. Besuch stattdessen <a rel="noopener" href="https://tagmanager.google.com">tagmanager.google.com</a> und hol dir einen Google Tag Manager Code, um Ihn auf allen Seiten deiner Website einzubinden. Log Dich bei GTM ein, implementiere den GA Code via Google Tag Manager und mach anschließend mit der <Link to="/de/google-analytics-einrichten">Einrichtung von Google Analytics</Link> weiter.<br />Alle möglichen Konfigurationen, wie z. B. das <Link to="/de/event-tracking">Tracken von Ereignissen</Link> oder das Hinzufügen anderer Marketing Tags, führst Du zukünftig via GTM durch.</p>

        <H as="h3">Was ist der Unterschied zwischen Google Analytics und Google Tag Manager?</H>
        <p>Google Analytics ist die JavaScript Bibliothek, die die Daten der Webseitenbesuche sammelt. Google Tag Manager ist die
          JavaScript Bibliothek, die auf der Webseite läuft, um andere Bibliotheken oder <em>Tools</em> wie Google Analytics
          zu implementieren. Da andere Tools ihre eigenen JavaScript snippets voraussetzen, implementiert man sie alle durch
          GTM und kann dadurch Regeln und Einstellungen für alle Tags gleichzeitig benutzen.</p>
        <H as="h3">Wo installiere ich den Google Tag Manager Code?</H>
        <p>Der <b>erste</b> Teil des Tags sollte so <em>hoch wie möglich</em> im <a target="_blank" rel="noopener" href="https://www.edv-lehrgang.de/head-bereich-in-html/">Head-Bereich</a> eingebunden
          werden. Ich empfehle zwar das Tag im <code>{`<head>`}</code> zu installieren, jedoch <b>nach</b> <code>&lt;style&gt;</code> oder <code>&lt;script&gt;</code> Tags, die wichtig für den Seitenaufbau sind. Ihr Ladevorgang soll nicht durch's Tag verzögert werden.<br />
          Der <b>zweite</b> Teil des Tags gewährleistet die fundamentale Funktionalität in Browsern mit deaktiviertem JavaScript. Setze das Tag so <em>hoch wie möglich</em> in das <code>&lt;body&gt;</code> Element.<br />
          Die Logik hinter der hohen Positionierung dient dem schnellen Laden von GTM. Somit können selbst-definierte
          Regeln früher auf den Aufbauprozess der Seite einwirken.</p>
        <ImgScreenshot
          src="google-analytics-einrichten/google-tag-manager-snippet.png"
          alt="Google Tag Manager Code"
          className="article-img"
          caption="Der GTM Code hat zwei Teile. Der erste Teil geht hoch in den Head-Bereich und der zweite oben ins Body-Tag."

        /><br />
        <ImgScreenshot
          src="data-layer/positionierung-data-layer-gtm.png"
          alt="Google Tag Manager Code Positionierung im Quelltext"
          className="article-img"
          caption="Die Reihenfolge im Quelltext ist immer: Erst der Data Layer, dann Google Tag Manager und zum Schluss der zweite Teil des Tags oben im Body-Tag."
        />
        <H as="h3">Ist Google Analytics bei Google Tag Manager schon enthalten?</H>
        <p>Nein, aber Google Tag Manager ermöglicht es, Google Analytics in Sekunden zu installieren, mit nur ein paar Klicks. Du musst nur deine <Link to="/de/google-analytics-einrichten#google-analytics-tracking-code">Google Analytics Tracking ID</Link> kennen. Generell ist Google Tag Manager jedoch keine Voraussetzung für Google Analytics. Beide sind voneinander <b>unabhängig</b>.</p>


        <H as="h3">Wo finde ich den Google Tag Manager?</H>
        <p>Log dich mit deinem Google Konto bei <a rel="noopener" href="https://tagmanager.google.com">tagmanager.google.com</a> ein, um Dich in die Benutzeroberfläche von Google Tag Manager einzuloggen. Du erhältst den Google Tag Manager Code, wenn Du ein neues Konto erstellst und als Zielplatform <b>Web</b> wählst. Um Loslegen zu können, installiere den GTM Code-Schnipsel jetzt auf allen Seiten deiner Webseite.</p>


        <Helmet>
          <script type="application/ld+json">{FAQs}</script>
        </Helmet>
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default GTMsetup;
